<template>
  <div v-if="isPermission && isEditData">
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row" style="margin: 5px">
            <div class="col-md-10">
              <h2 class="font-weight-normal">
                {{ $t("selectProduct") }}
              </h2>
            </div>
            <div class="col-3 col-sm-2 col-md-2 text-right">
              <CButton block color="light" @click="goBack()" class="align-items-center">
                <span><i class="fa fa-arrow-left mr-2"></i></span> {{ $t('back') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol>
              <h6 style="margin: 12px;">
                {{$t("selectProductDesc")}}
              </h6>
              <div style="max-height: auto; overflow-y: auto" class="mt-4 text-dark">
                <CDataTable
                  :id="'table'+tableKey"
                  v-if="!isLoading"
                  class="text-dark"
                  :fields="fields"
                  :items="showitems()"
                  hover
                  border
                  clickableRows
                >
                  <!-- <template slot="selected-header">
                    <div
                      class="custom-control custom-checkbox text-dark"
                      style="padding-left: 2rem"
                    >
                      <input
                        v-model="all"
                        type="checkbox"
                        @change="setAll()"
                        class="custom-control-input success cursor"
                        id="yyy"
                      />
                      <label class="custom-control-label cursor" for="yyy"> </label>
                    </div>
                  </template> -->
                  <template slot="nameproduct-header">
                    <p></p>
                  </template>
                  <template #selected="{ item, index }">
                    <td style="vertical-align: center" class="text-center">
                      <div
                        class="custom-control custom-checkbox text-dark"
                        style="padding-left: 2rem"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input success cursor"
                          v-model="item.selected"
                          @change="onSelectedAutoCompleteEvent(item)"
                          :id="index"
                        />
                        <label class="custom-control-label cursor" :for="index"> </label>
                      </div>
                    </td>
                  </template>
                  <template #namepicture="{ item, index }">
                    <td style="vertical-align: middle;" :for="index">
                      <img
                        v-if="item.img"
                        :src="item.img"
                        style="border-radius: 2px; aspect-ratio: 1 / 1"
                        class="img-fluid"
                        @error="noImgUrl"
                      />
                      <div
                        v-else
                        style="border-radius: 2px"
                        :style="{
                          'aspect-ratio': '1 / 1',
                          backgroundColor: item.indexColor,
                        }"
                      ></div>
                    </td>
                  </template>
                  <template #name="{ item }">
                    <td
                      class="text-dark font-weight-normal"
                      style="vertical-align: middle;"
                    >
                      {{ item.name }}
                    </td>
                  </template>
                  <template #brand="{ item }">
                    <td
                      class="text-dark font-weight-normal"
                      style="vertical-align: middle;"
                    >
                      {{ item.brand }}
                    </td>
                  </template>
                  <template #price="{ item, index }">
                    <td
                      class="text-dark font-weight-normal text-right"
                      :for="index"
                      style="vertical-align: middle;"
                    >
                      {{ item.price }}
                    </td>
                  </template>
                  <template #currency="{ item, index }">
                    <td
                      class="text-dark text-right font-weight-normal"
                      :for="index"
                      style="vertical-align: middle;"
                    >
                      {{ item.currency }}
                    </td>
                  </template>
                  <template #existed="{ item }">
                    <td class="text-dark font-weight-normal">
                      {{ item.existed }}
                    </td>
                  </template>
                  <template #moreItem="{ item }">
                    <td class="text-dark font-weight-normal">
                      <CButton v-if="item.has_model" @click="openModelModal(item)" color="outline-primary">
                        {{ $t('seemore') }}
                      </CButton>
                    </td>
                  </template>
                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8">
                        {{ $t("noItem") }}
                      </h4>
                    </div>
                  </template>
                </CDataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="productGet"></pagination>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol>
      <div class="row justify-content-end mb-4" style="margin: 0">
        <CButton
          class="btn btn-success col-lg-2 col-4 font-weight-normal"
          @click="openModal()"
          :disabled="selectedItems.length === 0"
        >
          {{ $t("save") }}
        </CButton>
      </div>
    </CCol>

    <!-- Confirm modal -->
    <CRow>
      <CModal
        :show.sync="confirmModal"
        color="success"
        :title="$t('confirmSaveProductTitle')"
        centered
        :footer="footer"
      >
        <br />
        <h4 class="text-center">{{ $t("confirmSaveProduct") }}</h4>
        <br />
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4">
              <CButton
                block
                color="success"
                v-if="loadingButton === true"
                v-on:click="productPull"
              >
                {{ $t("save") }}
              </CButton>
              <CButton block color="success" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t("save") }}
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton
                block
                color="light"
                @click="popupModal = false"
                class="font-weight-normal"
              >
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <CModal :show.sync="errorModal" color="danger" :title="$t('pullproductFail')" centered size="lg">
          <div>
            <br />
            <h4 class="text-center">{{ $t('plsLoginShopee') }}</h4>
            <br />
          </div>
  
          <template #footer>
            <CRow class="row justify-content-center">
              <CButton color="light" col="6" lg="6" md="6" block @click="authorize()">
                {{ $t('submit') }}
              </CButton>
            </CRow>
          </template>
        </CModal>
        <CModal :show.sync="ErrorModal" color="danger" :title="$t('pullproductFail')" centered size="lg">
          <div>
            <br />
            <h4 class="text-center">{{ $t('pullFailDesc') }}</h4>
            <br />
          </div>
  
          <template #footer>
            <CRow class="row justify-content-end">
              <CButton color="light" col="6" lg="4" md="4" block @click="this.$router.push('/ecommerce/EcommerceSetting')">
                {{ $t('Close') }}
              </CButton>
            </CRow>
          </template>
        </CModal>
        <CModal v-if="showModelModal" :show.sync="showModelModal" :title="$t('productPLU')" size="lg" centered>
          <CDataTable
            :fields="modelFields"
            :items="modelItems"
            hover
            border
            clickableRows
          >
            <template #namepicture="{ item, index }">
              <td style="vertical-align: middle;">
                <img
                  v-if="item.img"
                  :src="item.img"
                  style="border-radius: 2px; aspect-ratio: 1 / 1"
                  class="img-fluid"
                  @error="noImgUrl"
                />
              </td>
            </template>
            <template #name="{ item }">
              <td style="vertical-align: middle;" class="text-dark text-left font-weight-normal"
              >{{ item.name }}</td>
            </template>
            <template #price="{ item }">
              <td style="vertical-align: middle;" class="text-dark text-right font-weight-normal"
              >{{ item.price }}</td>
            </template>
            <template #currency="{ item }">
              <td style="vertical-align: middle;" class="text-dark text-right font-weight-normal"
              >{{ item.currency }}</td>
            </template>
            <template #status="{ item }">
              <td style="vertical-align: middle;" class="text-dark text-left font-weight-normal"
              >{{ item.status }}</td>
            </template>
          </CDataTable>
          <template #footer class="d-flex justify-content-center align-items-center">
            <div style="width: 30%;" class="d-flex justify-content-center align-items-center">
              <CButton color="light"  block @click="showModelModal = false">
                {{ $t('closed') }}
              </CButton>
            </div>
          </template>
        </CModal>

  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "@/services/local";
import util from "@/util/util";
import Multiselect from "vue-multiselect";
import permis from "@/util/permission";
import { CButton, CCardBody } from "@coreui/vue";
import Pagination from "@/containers/Pagination";
import ecommerce from "@/services/ecommerce";

export default {
  components: {
    Multiselect,
    Pagination,
  },
  data() {
    return {
      showModelModal: false,
      errorModal: false,
      ErrorModal: false,
      authorizeModal: false,
      isLoading: true,
      isSuccess: false,
      alert: false,
      data: [],
      datetime: new Date(),
      shop: this.$store.getters.shopObjectId,
      items: [],
      confirmModal: false,
      footer: "",
      loadingButton: true,
      permission: false,
      selectedItems: [],
      all: false,
      isshowAlert: 0,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: 10,
        itemCount: 0,
      },
      getItems: [],
      itemsData:[],
      tableKey: 0,
      checkItems: {},
      modelItems:[]
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "access", "permissions"]),
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    isPermission() {
      let path = "/inventory/stock-in";
      return permis.findPermissionRead("inventory", path);
    },
    isEditData() {
      let path = "/inventory/stock-in";
      return permis.findPermissionEdit("inventory", path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    startDate() {
      return moment(String(this.date.start)).format("DD MMMM YYYY");
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE;
    },
    fields() {
      return [
        {
          key: "selected",
          label: this.$i18n.t("selectd"),
          _style: "width:2%; vertical-align: middle; text-dark",
          _classes: "text-center font-weight-normal text-dark",
        },
        {
          key: "namepicture",
          label: this.$i18n.t("product"),
          _style: "width:3%; vertical-align: middle;border-left: hidden text-dark",
          _classes: "text-left font-weight-normal text-dark",
        },
        {
          key: "name",
          label: this.$i18n.t("product"),
          _style: "width:15%; vertical-align: middle;border-left: hidden text-dark",
          _classes: "text-left font-weight-normal text-dark",
        },
        {
          key: "brand",
          label: this.$i18n.t("brand"),
          _style: "width:5%; vertical-align: middle;border-left: hidden text-dark",
          _classes: "text-left font-weight-normal text-dark",
        },
        {
          key: "price",
          label: this.$i18n.t("price"),
          _classes: "text-right font-weight-normal text-dark",
          _style: "width:5%; vertical-align: middle;border-left: hidden text-dark",
        },
        {
          key: "currency",
          label: this.$i18n.t("currency"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:3%; text-dark",
        },
        {
          key: "existed",
          label: this.$i18n.t("status"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:5%; text-dark",
        },
        {
          key: "status",
          label: this.$i18n.t("salesInfo"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:5%; text-dark",
        },
        {
          key: "moreItem",
          label: this.$i18n.t("productPLU"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:5%; text-dark",
        },
      ];
    },
    modelFields() {
      return [
        {
          key: "namepicture",
          label: this.$i18n.t("product"),
          _style: "width:3%; vertical-align: middle;border-left: hidden text-dark",
          _classes: "text-left font-weight-normal text-dark",
        },
        {
          key: "name",
          label: this.$i18n.t("product"),
          _style: "width:15%; vertical-align: middle;border-left: hidden text-dark",
          _classes: "text-left font-weight-normal text-dark",
        },
        {
          key: "price",
          label: this.$i18n.t("price"),
          _classes: "text-right font-weight-normal text-dark",
          _style: "width:5%; vertical-align: middle;border-left: hidden text-dark",
        },
        {
          key: "currency",
          label: this.$i18n.t("currency"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:3%; text-dark",
        },
        {
          key: "status",
          label: this.$i18n.t("salesInfo"),
          _classes: "text-left font-weight-normal text-dark",
          _style: "width:5%; text-dark",
        },
      ];
    },
  },
  created() {
    this.productGet()
    this.shop = this.shops.find((i) => i.objectId === this.shop);
  },
  watch: {
  showItems: {
    handler(newVal) {
      this.showitems()
    },
    immediate: true, 
    deep: true  
  }
},
  methods: {
    ...util,
    openModelModal(item) {
    this.productModel(item)
    this.showModelModal = true
  },
    goBack() {
      this.$router.push('/ecommerce/ProductList');
    },
    authorize() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
        uid: uid,
        app: 'product'
      }
      // const headers = {
      //   shopObjectId: shopObjectId,
      // }
      ecommerce({
        url: 'api/v1.0/shopee/signin/link',
        params: params,
        method: 'GET',
        // headers: headers,
      })
        .then((response) => {
          const url = response.data.data.url || '-'
          if (url !== '-') {
            window.open(url, '_blank'); // เปิด URL ในแท็บใหม่
          }
        })
        .catch((error) => {
          console.log(error)
        })
    

      // window.location.href = '/ConnectedSuccess';
    },
    openModal() {
      if(this.selectedItems.length === 0){
        return this.comfirmModal = false
      }else {
        return this.confirmModal = true
      }
    },
    showitems() {
      let data = this.showItems;
      let detail = [];
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          let itemName = item.item_name !== undefined ? item.item_name : "-";
          let itemId = item.item_id !== undefined ? item.item_id : "-";
          let brandName =
            item.brand && item.brand.original_brand_name !== undefined
              ? item.brand.original_brand_name
              : "No Brand";
          let priceInfo =
            item.price_info &&
            item.price_info.length > 0 &&
            item.price_info[0].current_price !== undefined
              ? item.price_info[0].current_price
              : '-';
          let currency =
            item.price_info &&
            item.price_info.length > 0 &&
            item.price_info[0].currency !== undefined
              ? this.$t('baht')
              : "-";
          let imgURL =
            item.image &&
            item.image.image_url_list &&
            item.image.image_url_list.length > 0
              ? item.image.image_url_list[0]
              : null;
          let categoryId = item.category_id !== undefined ? item.category_id : "-";
          
          let existed = '-';
            if (this.checkItems.exists.includes(itemId)) {
              existed = this.$t('exists');
            } else if (this.checkItems.notExists.includes(itemId)) {
              existed = this.$t('noExists');
            }
          let status = ''
          if(item.item_status == 'NORMAL'){
            status = this.$t('available')
          }else {
            status = this.$t('unavailable')
          }
          let isSelected = this.selectedItems.some(selectedItem => selectedItem.id === itemId);

          detail.push({
            name: itemName,
            brand: brandName,
            category_id: categoryId,
            has_model: item.has_model,
            img: imgURL,
            price: util.convertCurrency(priceInfo),
            currency: currency,
            selected: isSelected,
            id: itemId,
            existed: existed, 
            status: status
          });
        }
      }
      return detail;
    },
    getDateLocale() {
      if (this.$i18n.locale == "en") {
        return { lang: "en" };
      } else {
        return { lang: "de" };
      }
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE;
    },
    onSelectedAutoCompleteEvent(item) {
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems = this.selectedItems.filter(
          (i) => i.id !== item.id
        )
      }
    },
    setAll() {
      this.all = !this.all;
      this.showItems.forEach((item) => {
        item.selected = this.all;
      });
    },
    productGet(page = 1) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        uid: uid,
        page: page,
      };
      ecommerce({
        url: "api/v1.0/shopee/product/list",
        params: params,
        method: "GET",
      })
        .then((res) => {
          if(res.data.error.code == 401 || res.data.error.code == 9100){
            this.errorModal = true
            this.authorize()
          }else{        
          const data = res.data.data;
          const itemIds = data.map((item) => item.item_id).join(",");
          if (res.data.paginate.items == undefined) {
            this.countProduct = 0;
            this.meta_data.items = 0;
          } else {
            this.countProduct = res.data.paginate.items;
            this.meta_data.items = res.data.paginate.items;
          }
          this.meta_data.last_page = res.data.paginate.pageCount;
          this.meta_data.current_page = res.data.paginate.currentPage;
          this.meta_data.itemCount = res.data.paginate.itemCount;
          this.meta_data.limit = res.data.paginate.perPage;
          this.getItems = itemIds;

          this.productInfo()
          this.checkProduct()
          this.tableKey += 1
        }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    productInfo() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        items: this.getItems,
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/detail",
        params: params,
        method: "GET",
      })
        .then((response) => {
          const data = response.data.data;
          this.showItems = data;
          this.checkProduct()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prepareModelData(models, variations) {
      return models.map((model) => {
        const variationIndex = model.tier_index[0];
        const variation = variations.option_list[variationIndex];

        const imageUrl = variation && variation.image ? variation.image.image_url : process.env.VUE_APP_NOIMAGE
        let currency =
            model.price_info &&
            model.price_info[0].currency !== undefined
              ? this.$t('baht')
              : "-";
        return {
          img: imageUrl,
          name: model.model_name,
          price: util.convertCurrency(model.price_info[0].original_price),
          currency: currency,
          status: model.model_status === 'MODEL_NORMAL' ? this.$t('available') : this.$t('unavailable'),
        };
      });
    },
    productModel(item) {
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        itemId: item.id,
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/models",
        params: params,
        method: "GET",
      })
        .then((response) => {
          const data = response.data.data
          const models = data.model
          const variations = data.tier_variation[0]

          this.modelItems = this.prepareModelData(models, variations)
          })
        .catch((error) => {
          console.log(error);
        });
    },
    productPull() {
      const uid = `${localStorage.getItem("shopsUid")}`
      const shopObjectId = this.shopObjectId
      let itemId = this.selectedItems.map(item => item.id).join(",")
      
      const params = {
        shopObjectId: shopObjectId,
        items: itemId,
      }
      
      ecommerce({
        url: "/api/v1.0/shopee/product/pull",
        params: params,
        method: "GET",
      })
      .then((response) => {
        const errorCode = response.data.error.code

        if (errorCode === 0) {
          this.$router.push('/ecommerce/ProductList')
        } else {
          this.ErrorModal = true
          this.confirmModal = false
        }
      })
      .catch((error) => {
        console.log(error);
        this.ErrorModal = true
        this.confirmModal = false
      })
    },
    checkProduct() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        items: this.getItems,  
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/check",
        params: params,
        method: "GET",
      })
        .then((response) => {
          const data = response.data.data
          this.checkItems = data
          this.isLoading = false
          this.tableKey += 1
        })
        .catch((error) => {
          console.error('Error from check API', error);
        });
    },
  },
};
</script>
<style scoped>
.multiselect__tags {
  min-height: 35px;
  padding: 0px 40px 0 8px;
}

.multiselect__placeholder {
  margin-top: 6px;
  margin-bottom: 7px;
}

.multiselect__single {
  margin-top: 6px;
  padding-left: 5px;
  margin-bottom: 8px;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
